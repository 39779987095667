/* eslint-disable quote-props */
/* eslint-disable no-whitespace-before-property */
/* eslint-disable dot-notation */
/* eslint-disable dot-notation */
/* eslint-disable dot-notation */
/* eslint-disable dot-notation */
/* eslint-disable dot-notation */
/* eslint-disable dot-notation */
/* eslint-disable dot-notation */
/* eslint-disable dot-notation */
<template>
  <div>
    <!-- <b-card v-if="!loading" title="Kick start your project 🚀"> -->
    <b-card v-if="!loading">
      <div @click.prevent="check_invoice()" class="btn btn-primary">
        Check Invoice
      </div>
      <b-card-text>All the best for your new project.</b-card-text>
    </b-card>
    <Skeleton v-else height="200px" :count="parseInt(3)" />

    <b-card v-if="!loading" title="Want to integrate JWT? 🔒">
      <b-card-text
        >We carefully crafted JWT flow so you can implement JWT with ease and
        with minimum efforts.</b-card-text
      >
      <b-card-text
        >Please read our JWT Documentation to get more out of JWT
        authentication.</b-card-text
      >
    </b-card>
  </div>
</template>

<script>
/* eslint-disable indent */
/* eslint-disable space-before-blocks */
/* eslint-disable comma-dangle */
/* eslint-disable space-infix-ops */
/* eslint-disable no-return-assign */
import { BCard, BCardText, BLink } from "bootstrap-vue";
import axios from "axios";

export default {
  name: "Home",
  components: {
    BCard,
    BCardText,
    BLink,
  },
  data() {
    return {
      loading: true,
    };
  },
  methods: {
    check_invoice() {
      this.$store.dispatch("spinnerLoading", true);
      axios.get(`check_invoice`).then((resp) => {
        if (resp.data.success) {
          this.$success_message(resp.data.message);
        } else {
          this.$error_message(resp.data.messadge, resp.data.errors);
        }
      });
    },
  },
  created() {
    setTimeout(() => {
      this.loading = false;
    }, 2000);
  },
};
</script>

<style>
</style>
